<template>
  <div class="passenger_box_two mt-2">
    <div class="passenger_header">
      <div v-if="device == 'desktop'">
        <h3>
          {{ $t('booking.room') +
              ' ' +
              (index + 1) +
              ' - ' +
              $t('booking.room') +
              ' ' +
              roomString
          }}
        </h3>
      </div>
      <div class="guest_details_body" v-else>
        <h4>
          {{ $t('booking.room-number') + ' ' + (index + 1) + ' : ' + roomString }}
        </h4>
      </div>
    </div>
    <div class="passenger_body">
      <p class="mb-3 position-relative">{{$t('booking.remarkName')}}</p>
      <UserInformation v-for="(user, idx) in users" :key="idx" :index="idx" :user="user" :originDate="originDate" :readOnly="readOnly" :hotelId="hotelId" @updateUser="updateUserInformation" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    UserInformation: () => import('@/components/booking/atoms/UserInformation'),
  },
  props: {
    room: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    category: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      users: [],
      readOnly: false,

      roomClass: '',
      roomType: '',

      roomString: '',

      isDuplicated: false,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
    }),
    originDate() {
      return this.room.checkIn;
    },
    hotelId() {
      return this.room.hotelId;
    },
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  created() {
    const retry = window.sessionStorage.getItem('retryState'),
      dataBook = JSON.parse(window.sessionStorage.getItem('BookingDetailInfo')),
      BookingDetailInfo = dataBook.data,
      { sendingStage } = dataBook;
    let paxIds;
    if (this.category !== 'Hotel_Only') {
      // general package
      ({ paxIds } = BookingDetailInfo.hotel[this.index].rooms);
    } else if (this.category === 'Hotel_Only') {
      paxIds = BookingDetailInfo.hotel.rooms;
    }

    // retry = {readOnly: true};
    if (
      retry !== 'null'
      && BookingDetailInfo.hotel.length > 0
      && paxIds.length > 0
      && sendingStage !== 'init'
    ) {
      const retryState = JSON.parse(retry);
      this.readOnly = retryState.readOnly;
      for (let i = 0; i < paxIds.length; i += 1) {
        const user = BookingDetailInfo.paxList.find((a) => a.id === paxIds[i]);
        user.groupID = this.index;
        this.users.push(user);
      }
    } else {
      let idx = 1;
      for (let i = 0; i < this.room.adult; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'adult',
          firstName: '',
          lastName: '',
          paxType: 'M',
          email: '',
          phone: '',
          birthDate: '',
        });
        idx += 1;
      }
      for (let i = 0; i < this.room.child; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'child',
          firstName: '',
          lastName: '',
          paxType: 'C',
          email: '',
          phone: '',
          birthDate: '',
        });
        idx += 1;
      }
      for (let i = 0; i < this.room.infant; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'infant',
          firstName: '',
          lastName: '',
          paxType: 'I',
          email: '',
          phone: '',
          birthDate: '',
        });
        idx += 1;
      }
    }
    this.users.forEach((user) => this.$emit('updatedUser', user));
    this.updateLabelWithLang();
  },
  methods: {
    updateLabelWithLang() {
      if (this.category !== 'Hotel_Only') {
        this.roomClass = this.room.translations?.Room_Class[this.room.roomClass.value][this.lang] || this.room.roomClass?.text;
        this.roomType = this.room.translations?.Room_Type[this.room.roomType.value][this.lang] || this.room.roomType?.value;
        this.roomString = this.roomClass;
      } else if (this.category === 'Hotel_Only') {
        this.roomString = this.room.roomType.value;
      }
    },
    updateUserInformation(pInfo) {
      const target = this.users.find(
        (user) => user.id === pInfo.id && user.groupID === pInfo.groupID,
      );
      Object.assign(target, pInfo);
      this.$emit('updatedUser', pInfo);
      if (Number(pInfo.id) === 1 && pInfo.validate && !this.isDuplicated) {
        for (let i = 1; i < this.users.length; i += 1) {
          // const { id } = this.users[i];
          // Object.assign(this.users[i], pInfo);
          // this.users[i].id = id;
          this.users[i].email = pInfo.email;
          this.users[i].phone = pInfo.phone;
          this.$emit('updatedUser', this.users[i]);
        }
        this.isDuplicated = true;
      }
    },
  },
};
</script>

<style scoped>
.passenger_header {
  padding: 20px 20px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 98, 172, 1) 0%,
    rgba(38, 135, 209, 1) 100%
  );
}

.passenger_body {
  padding: 20px 20px;
  /*min-height: 334px;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.passenger_body:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(11 102 172 / 95%);
}

@media (max-width: 479px) {
  .passenger_header {
    background: #fff;
    padding: 5px 15px;
  }
  .guest_details_body h4 {
    color: rgb(33, 37, 41);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .passenger_body {
    padding: 0px 15px;
  }

  .passenger_body:before {
    background: #fff;
  }

  .passenger_body > p.position-relative{
    color: #333;
  }

}
</style>
